import "tailwindcss/tailwind.css";

import React from "react";
import ReactDOM from "react-dom";
import { initializeApp, FirebaseOptions } from "firebase/app";
import { App } from "@/App";

if (process.env.NODE_ENV === "development") {
  // Only add for development environment.
  // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
  const axe = require("@axe-core/react");
  axe(React, ReactDOM, 1000);
}

const firebaseConfig: FirebaseOptions = JSON.parse(
  process.env.SERVICES
).firebase;

initializeApp(firebaseConfig);

ReactDOM.render(<App />, document.getElementById("app"));
