import { AdsSpinner } from "@aioli-digital/web-ads-react";
import Div100vh from "react-div-100vh";

const AppLoader = () => (
  <Div100vh className="flex items-center justify-center">
    <AdsSpinner
      className="mt-4 text-gray-600"
      size="xxlarge"
      aria-label="loading hip admin portal"
    />
  </Div100vh>
);

export default AppLoader;
