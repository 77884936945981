import { AdminLevel } from "@hip-group/hip-server";
import { getAuth, onAuthStateChanged, Unsubscribe } from "firebase/auth";
import React, { createContext, useContext, useEffect, useState } from "react";
import { resolveUser } from "@/App/utils/auth";

type AuthContextProps = {
  /** Whether or not the user is authenticated */
  isAuthenticated: boolean;
  /** The current users administrative level */
  adminLevel?: AdminLevel;
};

const AuthContext = createContext<AuthContextProps>({ isAuthenticated: false });
export const useAuth = () => useContext(AuthContext);

/*
 * 1. Resolve the current users authentication status.
 *
 * 2. Emulate Reacts `<Suspense />` api, waiting for authentication
 *    to resolve before rendering any children.
 *
 * 3. Subscribe to updates to authentication.
 */
export const createAuthProvider = () =>
  // 1
  resolveUser().then((isAuthenticatedOnMount) => ({
    // 2
    default: ((props) => {
      const [state, setState] = useState<AuthContextProps>({
        isAuthenticated: isAuthenticatedOnMount,
      });

      // 3
      useEffect(() => {
        const unsubscribe: Unsubscribe = onAuthStateChanged(
          getAuth(),
          async (user) => {
            if (!user) {
              setState({ isAuthenticated: false });
              return;
            }

            const token = await user.getIdTokenResult();
            const adminLevel: AdminLevel | undefined = token.claims
              ?.adminLevel as unknown as AdminLevel;

            setState({ isAuthenticated: true, adminLevel });
          }
        );
        return () => unsubscribe();
      }, []);

      return (
        <AuthContext.Provider value={state}>
          {props.children}
        </AuthContext.Provider>
      );
    }) as React.FC<{}>,
  }));
