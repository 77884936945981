import {
  Auth,
  browserLocalPersistence,
  browserSessionPersistence,
  getAuth,
  Persistence,
  signInWithEmailAndPassword,
} from "firebase/auth";

type LoginParams = {
  email: string;
  password: string;
  rememberMe: boolean;
};

/**
 * Resolve the current user as a promise
 */
export const resolveUser = (): Promise<boolean> =>
  new Promise((resolve, reject) => {
    const unsubscribe = getAuth().onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user !== null);
    }, reject);
  });

/** Log the user in via email and password */
export const login = async ({ rememberMe, email, password }: LoginParams) => {
  const persistenceType: Persistence = rememberMe
    ? browserLocalPersistence
    : browserSessionPersistence;

  const auth: Auth = getAuth();
  await auth.setPersistence(persistenceType);
  await signInWithEmailAndPassword(auth, email, password);
};

/** Log the user out */
export const logout = async () => getAuth().signOut();
