import AppLoader from "@atoms/AppLoader";
import { createAuthProvider } from "@hooks/useAuth";
import AppError from "@atoms/AppError";
import { lazy, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

const AuthProvider = lazy(createAuthProvider);
const Login = lazy(() => import("@pages/Login"));
const Example = lazy(() => import("@pages/Example"));
const RootLayout = lazy(() => import("@pages/RootLayout"));
const UrqlProvider = lazy(() => import("@/App/UrqlProvider"));

export const App = () => {
  return (
    <ErrorBoundary FallbackComponent={AppError}>
      <Suspense fallback={<AppLoader />}>
        <AuthProvider>
          <UrqlProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<RootLayout />}>
                  <Route path="/blogs" element={<Example />} />
                </Route>
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </BrowserRouter>
          </UrqlProvider>
        </AuthProvider>
      </Suspense>
    </ErrorBoundary>
  );
};
